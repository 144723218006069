<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>New Course Submission Form</v-card-title>
      <v-card-text v-if="submitted">
        <p>Thank you for submitting the new course submission form. If you would like to submit this form again, please click the button below.</p>
        <v-btn text color="primary" @click="resetForm()">Submit Again</v-btn>
      </v-card-text>
      <portal-form
        v-else
        :info-card="revision.infoCard"
        :info-card-text="revision.infoCardText"
        :sections="revision.sections"
        :init-form-data="initFormData"
        :section-type="revision.sectionType"
        title=""
        @submit="saveSubmission"></portal-form>
    </v-card>
  </v-container>
</template>
<style>
.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem
}
</style>
<script>
import { ref, computed } from '@vue/composition-api'
// import { getDiff } from '../../helpers/diffs'

export default {
  components: {
    PortalForm: () => import('@/components/forms/Form')
  },
  setup (props, { root }) {
    const terms = ref([])
    root.$feathers.service('system/term').find({ query: { start: { $gt: new Date() }, $sort: { start: 1 } } }).then(({ data }) => {
      data.forEach(({ term, label }) => {
        terms.value.push({ text: label, value: term })
      })
    })
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const submitted = ref(false)

    const gradeModeOptions = [
      { text: 'Not An Option', value: '' },
      { text: '1st Priority', value: '1' },
      { text: '2nd Priority', value: '2' },
      { text: '3rd Priority', value: '3' }
    ]
    const YesNo = [
      { text: 'Yes', value: 'Yes' },
      { text: 'No', value: 'No' }
    ]
    const coreOptions = [
      'None',
      'Fine Arts',
      'Humanities',
      'Natural Science Lab',
      'Social Science',
      'Diversity'
    ]
    const revision = ref({
      infoCard: false,
      infoCardText: '',
      sectionType: 'card',
      sections: [
        {
          title: '',
          inputs: [
            { label: 'Banner ID', name: 'submitter.id', input: 'text', md: 6, props: { readonly: true } },
            { label: 'Name', name: 'submitter.name', input: 'text', md: 6, props: { readonly: true } },
            { label: 'Department', name: 'department', input: 'text', md: 4, required: true },
            { label: 'Course Instructor', name: 'instructor', input: 'text', md: 4, required: true },
            { label: 'Effective Term', name: 'term', input: 'select', items: terms.value, md: 4, required: true, requiredText: 'Term is required' },
            { label: 'Course Title', name: 'title', input: 'text', md: 12, required: true },
            { label: '30-Character Course Title', name: 'shortTitle', input: 'text', md: 12, max: 30, required: true },
            { label: 'Credit Hours Type', name: 'credits.type', input: 'select', items: [{ text: 'Fixed', value: 'Fixed' }, { text: 'Variable', value: 'Variable' }], md: 6, required: true },
            { label: 'Credit Hours', name: 'credits.min', input: 'number', md: 6, required: true, min: 1, max: 5, ifs: [{ field: 'credits.type', op: '=', value: 'Fixed' }] },
            { label: 'Min Hours', name: 'credits.min', input: 'number', md: 3, required: true, min: 1, max: 5, ifs: [{ field: 'credits.type', op: '=', value: 'Variable' }] },
            { label: 'Max Hours', name: 'credits.max', input: 'number', md: 3, required: true, min: 1, max: 5, ifs: [{ field: 'credits.type', op: '=', value: 'Variable' }] },
            { label: 'Lecture Hours', name: 'hours.lecture', input: 'number', md: 4, required: true, min: 0, minText: 'Cannot be negative', max: 5, maxText: 'Max Hours is 5' },
            { label: 'Lab Hours', name: 'hours.lab', input: 'number', md: 4, required: true, min: 0, minText: 'Cannot be negative', max: 5, maxText: 'Max Hours is 5' },
            { label: 'Studio Hours', name: 'hours.studio', input: 'number', md: 4, required: true, min: 0, minText: 'Cannot be negative', max: 5, maxText: 'Max Hours is 5' },
            { label: 'Faculty Workload Hours', name: 'hours.workload', input: 'number', md: 6, required: true, min: 0, minText: 'Cannot be negative', max: 5, maxText: 'Max Hours is 5' },
            { label: 'Maximum Class Size', name: 'classSize', input: 'number', md: 6, required: true, min: 1, minText: 'Class size must be at least 1' },
            { info: '<b>Grading Mode Priority</b> - Indicate priority on each grading mode (or just one if it is the only available). If multiple, the 1st priority will be the default grade mode.', input: 'html-block' },
            { label: 'Standard Grading', name: 'gradeMode.standard', input: 'select', items: gradeModeOptions, md: 4 },
            { label: 'Pass/Fail', name: 'gradeMode.passfail', input: 'select', items: gradeModeOptions, md: 4 },
            { label: 'Credit/No-Credit', name: 'gradeMode.credit', input: 'select', items: gradeModeOptions, md: 4 },
            { label: 'Course Fee', name: 'courseFee', input: 'number', md: 6, min: 0, required: true },
            { label: 'Org Code (if different from the Dept Org Code)', name: 'orgCode', input: 'number', md: 6 },
            { label: 'Prerequisite(s); Include and/or if multiple courses; Leave blank if none', name: 'preReq', input: 'text' },
            { label: 'Has this course been taught before as a special topics course?', name: 'taughtBefore', input: 'select', items: YesNo, required: true },
            { label: 'What core requirement does this course fulfill, if any?', name: 'coreReq', input: 'combobox', hint: 'You may select an option or type in a value', persistentHint: true, items: coreOptions, required: true },
            { label: 'What major/minor group requirement does this course fulfill, if any?', name: 'majMinReq', input: 'combobox', hint: 'You may select an option or type in a value', persistentHint: true, items: coreOptions, required: true },
            { label: 'Will additional volumes be needed for this course?', name: 'libVol', input: 'select', items: YesNo, required: true },
            { label: 'Have you communicated with the Director of Library Services regarding needed materials?', name: 'libDir', input: 'select', items: YesNo, required: true, ifs: [{ field: 'libVol', op: '=', value: 'Yes' }] },
            { label: 'Have the syllabus and new course submission details for this course been approved by the department chair?', name: 'depChairEmail', input: 'select', items: YesNo, required: true },
            { label: 'Email of the department chair', name: 'depChairEmail', input: 'email', required: true },
            { label: 'Syllabus', name: 'syllabus', input: 'file', required: true }
          ]
        }
      ]
    })
    const initFormData = ref({
      submitter: {
        id: user.value.bannerId,
        name: user.value.name
      },
      department: '',
      term: '',
      instructor: '',
      title: '',
      shortTitle: '',
      credits: {
        type: 'Fixed',
        min: '',
        max: ''
      },
      hours: {
        lecture: '',
        lab: '',
        studio: '',
        workload: ''
      },
      classSize: '',
      gradeMode: {
        standard: '',
        passfail: '',
        credit: ''
      },
      courseFee: '',
      orgCode: '',
      preReq: '',
      taughtBefore: '',
      coreReq: 'None',
      majMinReq: 'None',
      libVol: '',
      libDir: '',
      syllabusApproved: '',
      depChairEmail: '',
      syllabus: null
    })
    function resetForm () {
      submitted.value = false
    }
    function saveSubmission (data) {
      console.log(data)
      // do something with the submitted data
      submitted.value = true
    }
    return {
      user,
      submitted,
      initFormData,
      revision,
      resetForm,
      saveSubmission
    }
  }
}
</script>
